var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "LBLDETAIL" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable &&
                          !_vm.disabled &&
                          _vm.isOld &&
                          !_vm.apprMode
                            ? _c("c-btn", {
                                attrs: { label: "LBLREMOVE", icon: "delete" },
                                on: { btnClicked: _vm.deleteAction },
                              })
                            : _vm._e(),
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  isSubmit: _vm.isSave,
                                  url: _vm.saveUrl,
                                  param: _vm.action,
                                  mappingType: _vm.mappingType,
                                  label: "LBLSAVE",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveAction,
                                  btnCallback: _vm.saveActionCallback,
                                },
                              })
                            : _vm._e(),
                          !_vm.apprMode
                            ? _c("c-appr-btn", {
                                ref: "appr-btn",
                                attrs: {
                                  name: "internal-action-appr-btn",
                                  editable: _vm.editable,
                                  approvalInfo: _vm.approvalInfo,
                                },
                                on: {
                                  beforeApprAction: _vm.approvalAction,
                                  callbackApprAction:
                                    _vm.approvalActionCallback,
                                  requestAfterAction: _vm.research,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "LBL0010105",
                            name: "actionName",
                          },
                          model: {
                            value: _vm.action.actionName,
                            callback: function ($$v) {
                              _vm.$set(_vm.action, "actionName", $$v)
                            },
                            expression: "action.actionName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: true,
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            range: true,
                            label: "LBLPERIOD",
                            name: "period",
                          },
                          model: {
                            value: _vm.action.period,
                            callback: function ($$v) {
                              _vm.$set(_vm.action, "period", $$v)
                            },
                            expression: "action.period",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-radio", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            codeGroupCd: "SAI_INTERNAL_ACTION_TYPE_CD",
                            label: "LBL0010117",
                            name: "saiInternalActionTypeCd",
                          },
                          model: {
                            value: _vm.action.saiInternalActionTypeCd,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.action,
                                "saiInternalActionTypeCd",
                                $$v
                              )
                            },
                            expression: "action.saiInternalActionTypeCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: true,
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.action.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.action, "plantCd", $$v)
                            },
                            expression: "action.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            rows: 5,
                            label: "LBL0010121",
                            name: "auditPurposeScope",
                          },
                          model: {
                            value: _vm.action.auditPurposeScope,
                            callback: function ($$v) {
                              _vm.$set(_vm.action, "auditPurposeScope", $$v)
                            },
                            expression: "action.auditPurposeScope",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            rows: 5,
                            label: "LBL0010122",
                            name: "auditPreRequire",
                          },
                          model: {
                            value: _vm.action.auditPreRequire,
                            callback: function ($$v) {
                              _vm.$set(_vm.action, "auditPreRequire", $$v)
                            },
                            expression: "action.auditPreRequire",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4" },
            [
              _c(
                "c-table",
                {
                  ref: "teamtable",
                  attrs: {
                    title: "LBL0010110",
                    columns: _vm.gridTeam.columns,
                    data: _vm.action.auditTeams,
                    gridHeight: _vm.gridTeam.height,
                    editable: _vm.editable && !_vm.disabled,
                    hideBottom: true,
                    isExcelDown: false,
                    filtering: false,
                    isFullScreen: false,
                    columnSetting: false,
                    selection: "multiple",
                    rowKey: "userId",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: { label: "LBLEXCEPT", icon: "remove" },
                                on: { btnClicked: _vm.deleteTeam },
                              })
                            : _vm._e(),
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: { label: "LBLADD", icon: "add" },
                                on: { btnClicked: _vm.addTeam },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "c-table",
        {
          ref: "actionTable",
          attrs: {
            title: "LBL0010123",
            columns: _vm.grid.columns,
            data: _vm.action.actionPlans,
            gridHeight: _vm.gridHeight,
            editable: _vm.editable && !_vm.disabled,
            hideBottom: true,
            isExcelDown: false,
            filtering: false,
            isFullScreen: false,
            columnSetting: false,
            selection: "multiple",
            rowKey: "saiInternalActionPlanId",
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "LBLEXCEPT", icon: "remove" },
                        on: { btnClicked: _vm.deletePlan },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "LBLADD", icon: "add" },
                        on: { btnClicked: _vm.addPlan },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }